<template>
<div>
  <div class="section section-plan">
    <div class="small-container">
      <h1 class="benefits-header">Selecciona tu plan</h1>
      <div class="plan-selection">
        <div class="plan">
          <img src="/images/550.png">
          <h2 class="plan-name">Tradizione</h2>
          <div class="plan-item">
            Incluye <b>1/2 Litro</b>  del gelato de tú elección al mes
          </div>
          <div class="price">$220 mxn <span>/ mes</span></div>
          <div class="action" @click="nextStep('price_1HNjSOEg3t6VS52kJhgwqW0D')">
            SELECCIONAR
          </div>
        </div>
        <div class="plan">
          <img src="/images/1100.png">
          <h2 class="plan-name">Classico</h2>
          <div class="plan-item">
            Incluye <b>1 Litro</b> del gelato de tú elección al mes
          </div>
          <div class="price">$400 mxn <span>/ mes</span></div>
          <div class="action" @click="nextStep('plan_H4UnPcb3j79iWF')">
            SELECCIONAR
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="benefits-section">
    <div class="small-container">
      <h3 class="h3">
        Todas las membresías incluyen:
      </h3>
      <div class="membership-benefits">
        <div class="membership-benefit">
          <img src="/images/check.svg">
          Precio preferencial (descuento del 10% en boutiques y del 20% en Cancún, Playa del Carmen y Mahahual)
        </div>
        <div class="membership-benefit">
          <img src="/images/check.svg">
          Experiencia Amorino (haz tu propia rosa en tienda y degusta todos los sabores)
        </div>
        <div class="membership-benefit">
          <img src="/images/check.svg">
          Waffle La Bella durante el mes de tu cumpleaños
        </div>
        <div class="membership-benefit">
          <img src="/images/check.svg">
          Invitaciones a eventos exclusivos para miembros
        </div>
        <div class="membership-benefit">
          <img src="/images/check.svg">
          Fila exclusiva para miembros (Fast Pass)
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ky from 'ky';

export default {
  data() {
    return {
      email: null,
      loggedIn: false,
    };
  },
  methods: {
    async nextStep(planId) {
      if (this.loggedIn) {
        await this.createCheckoutSession(planId);
        return;
      }
      const query = {
        planId,
      };

      if (this.email) {
        query.email = this.email;
      }

      this.$router.push({
        path: '/signup/account',
        query,
      });
    },
    async createCheckoutSession(planId) {
      const json = await ky.post(
        `${process.env.VUE_APP_BACKEND_URL}/login`,
        {
          json: {
            planId,
          },
        },
      ).json();

      window.location.href = json.url;
    },
  },
  mounted() {
    if (this.$route.query.loggedin) {
      this.loggedIn = true;
    }

    this.email = this.$route.query.email;
  },
};
</script>

<style lang="scss" scoped>

.plan-selection {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.section-plan {
  padding: 10px 0;
}
.plan {
  width: 48%;
  text-align: center;
}
.plan-name {
  text-transform: uppercase;
}
.plan-item {
  font-size: 16px;

  b {
    font-size: 20px;
  }
}
.price {
  font-size: 32px;
  margin-top: 28px;
  font-weight: 400;

  span {
    font-size: 18px;
  }
}
.action {
  display: inline-block;
  padding: 12px 40px;
  background-color:black;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 100px;
  margin-top: 20px;
}
.benefits-section {
  margin: 30px 0 20px 0;
}
.membership-benefits {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.membership-benefit {
  width: 48%;
  margin-bottom: 18px;
  font-size: 16px;

  img {
    height: 16px;
    width: auto;
    margin-right: 3px;
    vertical-align: top;
  }
}

@media screen and (max-width: 840px) {
  .plan-selection {
    flex-direction: column;
  }
  .plan {
    width: 100%;
  }
  .plan + .plan {
    margin-top: 30px;
  }
}
@media screen and (max-width: 550px) {
  .membership-benefit {
    width: 100%;
  }
}
</style>
